<template>
  <div>
    <commonbanner></commonbanner>
    <div class="container">
    <div class="mechanism_list mt-5">
      <a-tabs default-active-key="2">
        <a-tab-pane key="2" tab="市公共法律服务中心" force-render>
          <Collection
            name="provider"
            v-slot="{ collection }"
          >
            <a-row :gutter="[16, 16]" class="mt-5">
              <a-col
                :span="6"
                v-for="(item, index) in city.slice((provider.current-1)*provider.defaultPageSize,(provider.current-1)*provider.defaultPageSize+8)"
                :key="item.district_id"
              >
                <Lawcore :post="collection[index]" :type="item.district_name"></Lawcore>
              </a-col>
              <a-pagination
                class="mb-5"
                show-quick-jumper
                :current="provider.current"
                :defaultPageSize="provider.defaultPageSize"
                :total="city.length"
                @change="onChange"
              />
            </a-row>
          </Collection>
        </a-tab-pane>
        <a-tab-pane key="4" tab="区公共法律服务工作站" force-render>
          <Collection
            name="provider"
            v-slot="{ collection }"
          >
            <a-row :gutter="[16, 16]" class="mt-5">
              <a-col
                :span="6"
                v-for="(item, index) in area.slice((provider.current-1)*provider.defaultPageSize,(provider.current-1)*provider.defaultPageSize+8)"
                :key="item.district_id"
              >
                <Lawcore :post="collection[index]" :type="item.district_name"></Lawcore>
              </a-col>
              <a-pagination
                class="mb-5"
                show-quick-jumper
                :current="provider.current"
                :defaultPageSize="provider.defaultPageSize"
                :total="city.length"
                @change="onChange"
              />
            </a-row>
          </Collection>
        </a-tab-pane>
        <a-tab-pane key="5" tab="街镇公共法律服务工作室">
          <Collection
            name="provider"
            v-slot="{ collection }"
          >
            <a-row :gutter="[16, 16]" class="mt-5">
              <a-col
                :span="6"
                v-for="(item, index) in town.slice((provider.current-1)*provider.defaultPageSize,(provider.current-1)*provider.defaultPageSize+8)"
                :key="item.district_id"
              >
                <Lawcore :post="collection[index]" :type="item"></Lawcore>
              </a-col>
              <a-pagination
                class="mb-5"
                show-quick-jumper
                :current="provider.current"
                :defaultPageSize="provider.defaultPageSize"
                :total="city.length"
                @change="onChange"
              />
            </a-row>
          </Collection>
        </a-tab-pane>
        <a-tab-pane key="6" tab="村(社)公共法律服务工作室">
          <Collection
            name="provider"
            v-slot="{ collection }"
          >
            <a-row :gutter="[16, 16]" class="mt-5">
              <a-col
                :span="6"
                v-for="(item, index) in village.slice((provider.current-1)*provider.defaultPageSize,(provider.current-1)*provider.defaultPageSize+8)"
                :key="item.district_id"
              >
                <Lawcore :post="collection[index]" :type="item"></Lawcore>
              </a-col>
              <a-pagination
                class="mb-5"
                show-quick-jumper
                :current="provider.current"
                :defaultPageSize="provider.defaultPageSize"
                :total="city.length"
                @change="onChange"
              />
            </a-row>
          </Collection>
        </a-tab-pane>
      </a-tabs>
    </div>
  </div>
  </div>
</template>
<script>
import Lawcore from './Lawcore'
import Collection from '../../components/Collection'
import Commonbanner from '@/components/base/Commonbanner'
export default {
  name: 'lawService',
  components: {
    Commonbanner,
    Collection,
    Lawcore
  },
  methods: {
    onChange (page) {
      this.provider.current = page
    }
  },
  data () {
    return {
      providerQuery: {
        city: '',
        area: '',
        provider_name: '',
        person_name: '',
        provider_type: '司法鉴定机构'
      },
      provider: {
        current: 1,
        defaultPageSize: 8
      },
      city: [],
      area: [],
      town: [
        '秀川街道', '土门墩街道', '西站街道', '西园街道', '西湖街道', '建兰路街道', '龚家湾街道', '晏家坪街道', '敦煌路街道', '黄峪乡', '魏岭乡', '西果园镇'
      ],
      village: [
        '崔家崖', '大滩', '马滩', '郑家庄', '河湾堡', '土门墩', '西津西路西', '兰通厂', '建西西路', '西东路', '西津西路东', '小西坪', '西站东路', '西站西路', '三角线', '武威路'
      ],
      dataInfo: []
    }
  },
  created () {
    this.$api.get('/api/area').then((res) => {
      this.area = res.data.collection
    })
    this.$api.get('/api/city').then((res) => {
      this.city = res.data.collection
    })
  }
}
</script>
<style scoped src="../../assets/css/entity-org.css"></style>
