var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('commonbanner'),_c('div',{staticClass:"container"},[_c('div',{staticClass:"mechanism_list mt-5"},[_c('a-tabs',{attrs:{"default-active-key":"2"}},[_c('a-tab-pane',{key:"2",attrs:{"tab":"市公共法律服务中心","force-render":""}},[_c('Collection',{attrs:{"name":"provider"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var collection = ref.collection;
return [_c('a-row',{staticClass:"mt-5",attrs:{"gutter":[16, 16]}},[_vm._l((_vm.city.slice((_vm.provider.current-1)*_vm.provider.defaultPageSize,(_vm.provider.current-1)*_vm.provider.defaultPageSize+8)),function(item,index){return _c('a-col',{key:item.district_id,attrs:{"span":6}},[_c('Lawcore',{attrs:{"post":collection[index],"type":item.district_name}})],1)}),_c('a-pagination',{staticClass:"mb-5",attrs:{"show-quick-jumper":"","current":_vm.provider.current,"defaultPageSize":_vm.provider.defaultPageSize,"total":_vm.city.length},on:{"change":_vm.onChange}})],2)]}}])})],1),_c('a-tab-pane',{key:"4",attrs:{"tab":"区公共法律服务工作站","force-render":""}},[_c('Collection',{attrs:{"name":"provider"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var collection = ref.collection;
return [_c('a-row',{staticClass:"mt-5",attrs:{"gutter":[16, 16]}},[_vm._l((_vm.area.slice((_vm.provider.current-1)*_vm.provider.defaultPageSize,(_vm.provider.current-1)*_vm.provider.defaultPageSize+8)),function(item,index){return _c('a-col',{key:item.district_id,attrs:{"span":6}},[_c('Lawcore',{attrs:{"post":collection[index],"type":item.district_name}})],1)}),_c('a-pagination',{staticClass:"mb-5",attrs:{"show-quick-jumper":"","current":_vm.provider.current,"defaultPageSize":_vm.provider.defaultPageSize,"total":_vm.city.length},on:{"change":_vm.onChange}})],2)]}}])})],1),_c('a-tab-pane',{key:"5",attrs:{"tab":"街镇公共法律服务工作室"}},[_c('Collection',{attrs:{"name":"provider"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var collection = ref.collection;
return [_c('a-row',{staticClass:"mt-5",attrs:{"gutter":[16, 16]}},[_vm._l((_vm.town.slice((_vm.provider.current-1)*_vm.provider.defaultPageSize,(_vm.provider.current-1)*_vm.provider.defaultPageSize+8)),function(item,index){return _c('a-col',{key:item.district_id,attrs:{"span":6}},[_c('Lawcore',{attrs:{"post":collection[index],"type":item}})],1)}),_c('a-pagination',{staticClass:"mb-5",attrs:{"show-quick-jumper":"","current":_vm.provider.current,"defaultPageSize":_vm.provider.defaultPageSize,"total":_vm.city.length},on:{"change":_vm.onChange}})],2)]}}])})],1),_c('a-tab-pane',{key:"6",attrs:{"tab":"村(社)公共法律服务工作室"}},[_c('Collection',{attrs:{"name":"provider"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var collection = ref.collection;
return [_c('a-row',{staticClass:"mt-5",attrs:{"gutter":[16, 16]}},[_vm._l((_vm.village.slice((_vm.provider.current-1)*_vm.provider.defaultPageSize,(_vm.provider.current-1)*_vm.provider.defaultPageSize+8)),function(item,index){return _c('a-col',{key:item.district_id,attrs:{"span":6}},[_c('Lawcore',{attrs:{"post":collection[index],"type":item}})],1)}),_c('a-pagination',{staticClass:"mb-5",attrs:{"show-quick-jumper":"","current":_vm.provider.current,"defaultPageSize":_vm.provider.defaultPageSize,"total":_vm.city.length},on:{"change":_vm.onChange}})],2)]}}])})],1)],1)],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }